<template>
<div class="common-pop">
  <el-dialog
    :title="todo == 'add' ? '【新增】异常类型' : (todo == 'edit' ? '【修改】异常类型' : '【查看】异常类型')"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="150px">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="异常名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="异常名称"  :disabled="todo != 'view' ? false : true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="异常类型" prop="type">
            <el-input v-model="dataForm.type" placeholder="异常类型"  :disabled="todo != 'view' ? false : true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="处理方案" prop="programme">
            <el-input type="textarea" :rows="5" v-model="dataForm.programme" placeholder="处理方案" :disabled="todo != 'view' ? false : true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>·
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      calculationList: [],
      templateList: [],
      dataForm: {
        id: null,
        name: null,
        type: null,
        programme: null
      },
      todo: 'view',
      dataRule: {
        name: [
          { required: true, message: '异常名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/exceptionType/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.name = data.name
            this.dataForm.type = data.type
            this.dataForm.programme = data.programme
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/exceptionType/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'type': this.dataForm.type,
              'programme': this.dataForm.programme
            })
          }).then(({data}) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
